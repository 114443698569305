import React, { useEffect, useState, useRef, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { UIContext } from "../../providers/UIProvider";
import { useData } from "../../providers/DataProvider";
import AxiosInstance from "../../utils/axiosInstance";
import { downloadFile } from "../../utils/downloadFile";

type CMData = {
  employeeID: string;
  cm: string;
  bonusPercent: number;
  closingBonus: number;
};

type JobData = {
  jobId: number;
  areaID: number;
  areaName: string;
  projectNumber: string;
  subdivision: string;
  stage5Bonus: number;
  closingBonus: number;
  jobNumber: string;
  salesPrice: number;
  jobAddress: string;
  closeDate: string;
  stageCode: string;
  stage5Date: string;
  bonusStatusId: number;
  cMs: CMData[];
  notes: string;
};

const formatDateTime = (): string => {
  const now = new Date();
  return now.toISOString().slice(0, 16).replace(/[-T]/g, "").replace(/:/g, "");
};

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export default function Payroll() {
  const { role } = useData();
  const { accounts } = useMsal();
  const [state, dispatch] = useContext<any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [rows, setRows] = useState<JobData[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isPayroll = accounts[0].idTokenClaims?.roles?.includes("Payroll");

  const downloadReport = async () => {
    if (
      window.confirm(
        "Are you sure you want to submit the current payroll report? You will receive an automatic download."
      )
    ) {
      setLoadingReport(true);
      const formattedDate = formatDateTime();
      const filename = `CBA_Payout_${formattedDate}.xlsx`;
      await downloadFile(
        "/api/cba/payout-process/create-spreadsheet",
        filename
      );
      setLoadingReport(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setFileModalOpen(true);
    }
  };

  const handleCancelUpload = () => {
    setFile(null);
    setFileModalOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleConfirmUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("File", file);

      setUploading(true);

      try {
        await AxiosInstance.post(
          "/api/cba/payout-process/upload-paid-info-spreadsheet",
          formData
        );
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: "File Uploaded Successfully",
            severity: "success",
          },
        });
        setFile(null);
        setFileModalOpen(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        alert("Failed to upload file.");
      } finally {
        setUploading(false);
      }
    }
  };

  const loadTable = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get<JobData[]>(
        "/api/cba/payout-details",
        {
          headers: {
            roleType: role,
          },
        }
      );

      if (res && res.data) {
        setRows(res.data);
      }
    } catch (error) {
      console.error("Failed to load table data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTable();
  }, []);

  useEffect(() => {
    if (accounts[0].idTokenClaims?.roles && !isPayroll) {
      navigate("/");
    }
  }, [isPayroll, accounts, navigate]);

  const showInstructions = () => {
    alert(
      "How to use this page:\n\n" +
        "1. The table shows the current payroll report.\n" +
        "2. Use the 'Upload Report' button to upload a new payroll spreadsheet.\n" +
        "3. Click 'Download Report' to generate and download the current payroll report.\n" +
        "4. Ensure all projects are approved before generating the final report."
    );
  };

  return (
    <main className="flex-grow p-4">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="p-4 bg-gray-50 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div className="flex items-center align-middle">
              <h2 className="text-xl font-semibold">Reports</h2>
              <button
                onClick={showInstructions}
                className="ml-2 text-gray-500 hover:text-gray-700"
                title="How to use this page"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="flex gap-2 items-center">
              <label className="cursor-pointer bg-primary-500 text-white px-3 py-2 rounded-md flex items-center text-sm hover:brightness-150 transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
                {uploading ? "Uploading..." : "Upload Report"}
                <input
                  type="file"
                  className="hidden"
                  accept=".xlsx"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  disabled={uploading}
                />
              </label>

              <button
                className="bg-primary-500 text-white px-3 py-2 rounded-md flex items-center text-sm hover:brightness-150 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={downloadReport}
                disabled={!rows.length || loadingReport}
              >
                {loadingReport ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
                Generate Report
              </button>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Job Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Employee ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  CM Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Closing Bonus
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan={4} className="px-6 py-4 text-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-900 mx-auto"></div>
                  </td>
                </tr>
              ) : rows.length > 0 ? (
                rows.flatMap((row) =>
                  row.cMs.map((cm, index) => (
                    <tr
                      key={`${row.jobId}-${cm.employeeID}`}
                      className="hover:bg-gray-100"
                    >
                      {index === 0 && (
                        <td
                          rowSpan={row.cMs.length}
                          className="px-6 py-4 whitespace-nowrap"
                        >
                          {row.jobNumber}
                        </td>
                      )}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {cm.employeeID}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{cm.cm}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {formatCurrency(cm.closingBonus)}
                      </td>
                    </tr>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan={4} className="px-6 py-4 text-center">
                    <div className="py-20">
                      <h6 className="text-xl font-semibold">
                        No items in payroll report.
                      </h6>
                      <p className="text-gray-500 mt-2">
                        Please approve more projects before returning.
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
