import { useState, useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { UIContext } from "../../providers/UIProvider";
import { useData } from "../../providers/DataProvider";
import { ProjectsProps } from "../../providers/types";
import axiosInstance from "../../utils/axiosInstance";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

export default function Admin() {
  const [state, dispatch]: any = useContext(UIContext);
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { loadProjects, projects } = useData();
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<
    number | undefined
  >(undefined);
  const [selectedProject, setSelectedProject] = useState<
    ProjectsProps | undefined
  >(undefined);
  const [selectedSteps, setSelectedSteps] = useState({
    pm: false,
    spm: false,
  });

  const isAdmin = accounts[0].idTokenClaims?.roles?.includes("Admin");

  useEffect(() => {
    if (accounts[0].idTokenClaims?.roles && !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, accounts, navigate]);

  useEffect(() => {
    loadProjects();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProject) {
      alert("Please select a project");
      return;
    }

    try {
      await axiosInstance.post("/api/cba/UpdateSkipReviews", {
        ProjectId: selectedProject.projectId,
        CreatedBy: accounts[0].username,
        IsPmSkipped: selectedSteps.pm,
        IsSPmSkipped: selectedSteps.spm,
      });

      setSelectedProject(undefined);
      setSelectedProjectId(undefined);
      setSelectedSteps({ pm: false, spm: false });
      dispatch({
        type: "Snackbar",
        payload: {
          show: true,
          message: "Successfully skipped review steps",
          severity: "success",
        },
      });
      loadProjects();
    } catch (error) {
      console.error(error);
    }
  };

  const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const project = projects.find(
      (p) => p.projectId === Number(e.target.value)
    );
    setSelectedProjectId(Number(e.target.value));
    setSelectedProject(project);
    setSelectedSteps({
      pm: project?.isPmSkipped || false,
      spm: project?.isSpmSkipped || false,
    });
  };

  const handleClearProject = () => {
    setSelectedProjectId(undefined);
    setSelectedProject(undefined);
    setSelectedSteps({ pm: false, spm: false });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12 mt-12">
        <div className="px-8 grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Skip Review
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select a project and update the review steps you want to skip.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label
                htmlFor="project-select"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Project
              </label>
              <div className="mt-2 flex items-center">
                <select
                  id="project-select"
                  value={selectedProjectId || ""}
                  onChange={handleProjectChange}
                  onFocus={() => setSelectOpen(true)}
                  onBlur={() => setSelectOpen(false)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">Select a project</option>
                  {projects.map((project) => (
                    <option key={project.projectId} value={project.projectId}>
                      {`${project.subdivision} - ${project.projectNumber}`}
                      {project.skipReviews.length >= 2 && selectOpen && " ✓"}
                    </option>
                  ))}
                </select>
                {selectedProject && (
                  <button
                    type="button"
                    onClick={handleClearProject}
                    className="ml-2 text-sm text-gray-500 hover:text-gray-700"
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>

            <div className="col-span-full">
              <fieldset disabled={!selectedProject}>
                <legend
                  className={`text-sm font-semibold leading-6 ${
                    selectedProject ? "text-gray-900" : "text-gray-400"
                  }`}
                >
                  Review Steps to Skip{" "}
                  {!selectedProject && "(Select a project first)"}
                </legend>
                <div
                  className={`mt-6 space-y-6 ${
                    !selectedProject && "opacity-50"
                  }`}
                >
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="pm-skip"
                        name="pm-skip"
                        type="checkbox"
                        checked={selectedSteps.pm}
                        onChange={() =>
                          setSelectedSteps({
                            ...selectedSteps,
                            pm: !selectedSteps.pm,
                          })
                        }
                        className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600 cursor-not-allowed"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="pm-skip"
                        className="font-medium text-gray-900 cursor-not-allowed"
                      >
                        PM
                      </label>
                      <p className="text-gray-500">
                        Skip the Project Manager review step.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="spm-skip"
                        name="spm-skip"
                        type="checkbox"
                        checked={selectedSteps.spm}
                        onChange={() =>
                          setSelectedSteps({
                            ...selectedSteps,
                            spm: !selectedSteps.spm,
                          })
                        }
                        className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600 cursor-not-allowed"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="spm-skip"
                        className="font-medium text-gray-900 cursor-not-allowed"
                      >
                        SPM
                      </label>
                      <p className="text-gray-500">
                        Skip the Senior Project Manager review step.
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div className="p-8 mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-primary-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:brightness-150 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
