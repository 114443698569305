import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { Client } from "@microsoft/microsoft-graph-client";

/**
 * Renders a sign-out button or user avatar with dropdown
 */
export const SignOutButton = () => {
  const [userPhoto, setUserPhoto] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { instance, accounts } = useMsal();
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchUserPhoto = async () => {
      if (accounts[0]) {
        try {
          const accessToken = await instance.acquireTokenSilent({
            scopes: ["User.Read"],
            account: accounts[0],
          });

          const graphClient = Client.init({
            authProvider: (done) => {
              done(null, accessToken.accessToken);
            },
          });

          // Add error handling for the API request
          const response = await graphClient.api("/me/photo/$value").get();

          if (response) {
            const photoUrl = URL.createObjectURL(response);
            setUserPhoto(photoUrl);
          } else {
            console.warn("User photo not available");
          }
        } catch (error) {
          if (error.statusCode === 404) {
            console.warn("User photo not found");
          } else if (error.message.includes("consent_required")) {
            console.error("Permission to access user photo is missing");
          } else {
            console.error("Error fetching user photo:", error);
          }
          // Set a default avatar or handle the error as needed
          setUserPhoto(null);
        }
      }
    };

    fetchUserPhoto();
  }, [accounts, instance]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  if (!accounts[0]) {
    return null; // Don't render anything if user is not signed in
  }

  return (
    <div className="relative" ref={menuRef}>
      <button
        className="rounded-full overflow-hidden focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {userPhoto ? (
          <img src={userPhoto} alt="User" className="w-8 h-8 object-cover" />
        ) : (
          <div className="w-8 h-8 bg-gray-300 flex items-center justify-center text-gray-600">
            {accounts[0].name.charAt(0).toUpperCase()}
          </div>
        )}
      </button>
      {isMenuOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
