import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import {
  HomeIcon,
  UserGroupIcon,
  CurrencyDollarIcon,
  ClipboardDocumentCheckIcon,
  FolderIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useMsal } from "@azure/msal-react";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const { accounts } = useMsal();
  const isActive = (path: string) => location.pathname === path;

  const userRoles = (accounts[0]?.idTokenClaims?.roles as string[]) || [];
  const hasPayrollAccess = userRoles.includes("Payroll");
  const hasAdminAccess = userRoles.includes("Admin");
  const hasUploadAccess = userRoles.includes("Uploader");

  return (
    <div className="flex flex-col h-screen antialiased bg-gray-50">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <aside
          className="fixed top-0 left-0 z-40 w-48 h-screen pt-14 bg-white border-r border-gray-200"
          aria-label="Sidenav"
        >
          <div className="overflow-y-auto py-5 px-3 h-full bg-white">
            <ul className="space-y-2">
              <li>
                <a
                  href="/"
                  className={`flex items-center p-2 text-base font-normal rounded-lg group ${
                    isActive("/")
                      ? "bg-gray-100  text-gray-900"
                      : "text-gray-900 hover:bg-gray-100"
                  }`}
                >
                  <HomeIcon className="w-6 h-6 text-primary-500 transition duration-75 group-hover:text-gray-900" />
                  <span className="ml-3">Home</span>
                </a>
              </li>
              {hasUploadAccess && (
                <li>
                  <a
                    href="/upload"
                    className={`flex items-center p-2 text-base font-normal rounded-lg group ${
                      isActive("/upload")
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                  >
                    <FolderIcon className="w-6 h-6 text-primary-500 transition duration-75 group-hover:text-gray-900" />
                    <span className="ml-3">File Explorer</span>
                  </a>
                </li>
              )}
              <li>
                <a
                  href="/upload/create"
                  className={`flex items-center p-2 text-base font-normal rounded-lg group ${
                    isActive("/upload/create")
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-900 hover:bg-gray-100"
                  }`}
                >
                  <MagnifyingGlassIcon className="w-6 h-6 text-primary-500 transition duration-75 group-hover:text-gray-900" />
                  <span className="ml-3">Find a project</span>
                </a>
              </li>
              <li>
                <a
                  href="/review"
                  className={`flex items-center p-2 text-base font-normal rounded-lg group ${
                    isActive("/review")
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-900 hover:bg-gray-100"
                  }`}
                >
                  <ClipboardDocumentCheckIcon className="w-6 h-6 text-primary-500 transition duration-75 group-hover:text-gray-900" />
                  <span className="ml-3">Review</span>
                </a>
              </li>
              {hasAdminAccess && (
                <li>
                  <a
                    href="/admin"
                    className={`flex items-center p-2 text-base font-normal rounded-lg group ${
                      isActive("/admin")
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                  >
                    <UserGroupIcon className="w-6 h-6 text-primary-500 transition duration-75 group-hover:text-gray-900" />
                    <span className="ml-3">Admin</span>
                  </a>
                </li>
              )}
              {hasPayrollAccess && (
                <li>
                  <a
                    href="/payroll"
                    className={`flex items-center p-2 text-base font-normal rounded-lg group ${
                      isActive("/payroll")
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                  >
                    <CurrencyDollarIcon className="w-6 h-6 text-primary-500 transition duration-75 group-hover:text-gray-900" />
                    <span className="ml-3">Payroll</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </aside>

        <main className="flex-1 ml-48 pt-14 overflow-y-auto">
          <div className="h-full">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
