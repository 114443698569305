/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from "@mui/material";
import { useData } from "../../providers/DataProvider";
import FindProjectForm from "../../components/FindProjectForm";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export default function FileUploadSearch() {
  const { instance, accounts } = useMsal();
  const { selectedCompany, selectedRegion, loadCommunities } = useData();

  useEffect(() => {
    if (selectedCompany && selectedRegion) {
      loadCommunities();
    }
  }, [selectedCompany, selectedRegion]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Backdrop className="z-50" open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <main className="flex-grow flex items-center justify-center">
        <FindProjectForm />
      </main>
    </div>
  );
}
