import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import AxiosInstance from "../../utils/axiosInstance";

interface User {
  id: string;
  name: string;
}

const FilterModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onApply: (filters: any) => void;
}> = ({ isOpen, onClose, onApply }) => {
  const [filters, setFilters] = useState({
    address: "",
    builders: [] as string[],
    workflowUsers: [] as string[],
    jobIds: "",
  });

  const [allUsers, setAllUsers] = useState<User[]>([]);

  useEffect(() => {
    // Fetch all users when the modal opens
    if (isOpen) {
      fetchAllUsers();
    }
  }, [isOpen]);

  const fetchAllUsers = async () => {
    try {
      const response = await AxiosInstance.post("/api/cba/builders/search");
      setAllUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleMultiSelectChange = (name: string, value: string[]) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleApply = () => {
    const appliedFilters = {
      ...filters,
      jobIds: filters.jobIds ? filters.jobIds.split(",").map(id => id.trim()) : [],
    };
    onApply(appliedFilters);
    onClose();
  };

  const handleReset = () => {
    setFilters({
      address: "",
      builders: [],
      workflowUsers: [],
      jobIds: "",
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="text-lg font-semibold text-gray-900">
        Advanced Filters
      </DialogTitle>
      <DialogContent className="space-y-4">
        <TextField
          fullWidth
          label="Address"
          name="address"
          value={filters.address}
          onChange={handleInputChange}
          margin="normal"
        />
        <Autocomplete
          multiple
          options={allUsers}
          getOptionLabel={(option) => option.name}
          value={allUsers.filter((user) => filters.builders.includes(user.id))}
          onChange={(_, newValue) => handleMultiSelectChange("builders", newValue.map((v) => v.id))}
          renderInput={(params) => <TextField {...params} label="Builders" />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.name} {...getTagProps({ index })} />
            ))
          }
        />
        <Autocomplete
          multiple
          options={allUsers}
          getOptionLabel={(option) => option.name}
          value={allUsers.filter((user) => filters.workflowUsers.includes(user.id))}
          onChange={(_, newValue) => handleMultiSelectChange("workflowUsers", newValue.map((v) => v.id))}
          renderInput={(params) => <TextField {...params} label="Workflow Users" />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.name} {...getTagProps({ index })} />
            ))
          }
        />
        <TextField
          fullWidth
          label="Job IDs (comma-separated)"
          name="jobIds"
          value={filters.jobIds}
          onChange={handleInputChange}
          margin="normal"
          helperText="Enter job IDs separated by commas"
        />
      </DialogContent>
      <DialogActions className="bg-gray-50 px-4 py-3">
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={handleReset}
        >
          Reset
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:brightness-150"
          onClick={handleApply}
        >
          Apply Filters
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
