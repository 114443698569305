/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Link } from "react-router-dom";
import { useData } from "../../providers/DataProvider";

const FindProjectForm: React.FC = () => {
  const {
    communities2,
    allJobs,
    selectedCommunity,
    selectedJob,
    setSelectedCommunity,
    selectedRegion,
    setSelectedJob,
  } = useData();
  const [jobs, setJobs] = useState<any[]>(allJobs);

  useEffect(() => {
    const filteredJobs = allJobs
      .filter((job) => job.projectId === selectedCommunity?.projectId)
      ?.sort((a, b) => a.jobAddress.localeCompare(b.jobAddress));

    setJobs(filteredJobs);
  }, [selectedCommunity, allJobs]);

  useEffect(() => {
    if (selectedRegion) {
      setSelectedCommunity(null);
      setSelectedJob(null);
    }
  }, [selectedRegion]);

  return (
    <div className="container mx-auto px-4 flex justify-center items-center min-h-screen">
      <div className="w-full max-w-xl mt-[-20rem]">
        <form className="flex flex-col justify-center gap-6 bg-white p-6 rounded-lg shadow-lg">
          <div>
            <h2 className="text-xl font-semibold text-center">
              Find your project
            </h2>
          </div>
          <FormControl size="small" fullWidth>
            <InputLabel id="community-select-label">Community</InputLabel>
            <Select
              inputProps={{ shrink: true }}
              labelId="community-select-label"
              id="community-select"
              value={selectedCommunity?.subDivision}
              label="Community"
              onChange={(e) => {
                const newCommunityId = e.target.value;
                const selected = communities2.find(
                  (c) => c.subDivision === newCommunityId
                );
                setSelectedCommunity(selected || null);
                setSelectedJob(null);
              }}
            >
              {communities2.length > 0 ? (
                communities2.map((community) => (
                  <MenuItem
                    key={community.projectId}
                    value={community.subDivision}
                  >
                    {`${community.subDivision} - ${community.projectNumber}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No communities in region</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth>
            <InputLabel id="job-select-label">Job</InputLabel>
            <Select
              size="small"
              labelId="job-select-label"
              id="job-select"
              value={selectedJob?.jobId}
              disabled={!Boolean(selectedCommunity?.company)}
              label="Job"
              onChange={(e) => {
                const newJobId = e.target.value;
                const selectedJob = allJobs.find(
                  (job) => job.jobId.toString() === newJobId
                );
                setSelectedJob(selectedJob || null);
              }}
              // variant="outlined"
              // inputProps={{ shrink: true }}
            >
              {jobs.map((job) => (
                <MenuItem key={job.jobId} value={job.jobId.toString()}>
                  {`${job.jobAddress} - ${job.jobNumber}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="flex justify-end">
            <div>
              <a
                href={`/upload/create/${selectedJob?.jobId}`}
                className={`w-full py-2 px-4 text-center text-white font-semibold rounded ${
                  !selectedCommunity || !selectedJob
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-primary-500 hover:brightness-150"
                }`}
                onClick={(e) => {
                  if (!selectedCommunity || !selectedJob) {
                    e.preventDefault();
                  }
                }}
              >
                Search
              </a>
            </div>
          </div>
        </form>
        <div className="mt-4 text-center">
          <Link
            to="/upload"
            className="text-sm text-gray-600 hover:text-gray-800 hover:underline"
          >
            Return to File Explorer?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FindProjectForm;
