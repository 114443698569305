import React, { useState, useEffect } from "react";
import ReusableDialog from "../../components/Dialog";
import axiosInstance from "../../utils/axiosInstance";
import moment from "moment";
import { commentsProps } from "../../providers/types";

type Props = {
  jobId: number;
};

const Comments = ({ jobId }: Props) => {
  const [open, setOpen] = useState(false);
  const [comments, setComments] = React.useState<commentsProps[]>([]);

  const loadComments = (id: number) => {
    axiosInstance
      .get(`/api/cba/comments/${id}`)
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (jobId && open) {
      loadComments(jobId);
    }
  }, [jobId, open]);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth="md"
        buttonVariant="outlined"
        content={
          <div className="p-4">
            {comments.length ? (
              <div className="space-y-4 divide-y divide-gray-200">
                {comments.map((comment) => (
                  <div key={comment?.id} className="pt-4 break-words">
                    <p className="text-sm font-medium text-gray-900">
                      {comment?.createdBy}
                    </p>
                    <p className="text-xs text-gray-500">
                      {comment?.fileAttachmentTypeName || "General Comment"}
                    </p>
                    <p className="my-2 text-sm text-gray-700 pl-1">
                      {comment?.comment}
                    </p>
                    <p className="text-xs text-gray-500 text-right">
                      {moment(comment?.createdOn).format("LLL")}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-sm text-gray-500">No comments.</p>
            )}
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setOpen(false)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Close
              </button>
            </div>
          </div>
        }
        buttonText="Comments"
        title="Comments"
      />
    </>
  );
};

export default Comments;
