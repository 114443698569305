import React, { useState, useCallback } from "react";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { Box, Link, Skeleton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import Logo from "../../assets/images/logo.svg";
import axiosInstance from "../../utils/axiosInstance";
import { useData } from "../../providers/DataProvider";
import {
  CommunitiesProps,
  CompanyProps,
  JobProps,
  monthNames,
} from "../../providers/types";

interface FileTreeDrawerProps {
  companies: CompanyProps[];
  communities: CommunitiesProps[];
  jobs: JobProps[];
  drawerWidth: number;
  current: { files: any[]; type: string; prev: any };
  setCurrent: React.Dispatch<
    React.SetStateAction<{
      files: any[];
      type: string;
      prev: any;
    }>
  >;
  onWidthChange: (width: number) => void;
}

const FileTree: React.FC<FileTreeDrawerProps> = ({
  companies,
  communities,
  jobs,
  drawerWidth,
  current,
  setCurrent,
  onWidthChange,
}) => {
  const { role } = useData();
  const loadJobDetails = (jobId: number) => {
    axiosInstance
      .get(`/api/cba/job-details/${jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setCurrent({
          ...current,
          files: response.data.fileAttachments,
          type: "file",
          prev: {
            jobId: jobId,
            job: response.data,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  const defaultExpandedNodeIds = companies.map(
    (company) => `company-${company.companyId}`
  );

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  // Variable to control the time length for archiving (in months)
  const archiveThreshold = 12;

  const isWithinThreshold = (year: number, month: number) => {
    const date = new Date(year, month - 1);
    const thresholdDate = new Date(currentDate);
    thresholdDate.setMonth(thresholdDate.getMonth() - archiveThreshold);
    return date >= thresholdDate;
  };

  const renderYearContent = (
    company: CompanyProps,
    year: number,
    isArchive: boolean
  ) => (
    <TreeItem
      key={`company-${company.companyId}-year-${year}${
        isArchive ? "-archive" : ""
      }`}
      nodeId={`company-${company.companyId}-year-${year}${
        isArchive ? "-archive" : ""
      }`}
      label={`${year}`}
    >
      {[
        ...new Set(
          communities
            .filter(
              (community) =>
                community.companyId === company.companyId &&
                community.year === year &&
                isWithinThreshold(year, community.month) === !isArchive
            )
            .map((c) => c.month)
        ),
      ].map((month) => (
        <TreeItem
          key={`company-${company.companyId}-year-${year}-month-${month}${
            isArchive ? "-archive" : ""
          }`}
          nodeId={`company-${company.companyId}-year-${year}-month-${month}${
            isArchive ? "-archive" : ""
          }`}
          label={`${monthNames[month - 1]}`}
        >
          {communities
            .filter(
              (community) =>
                community.companyId === company.companyId &&
                community.year === year &&
                community.month === month
            )
            ?.sort((a, b) => a.subDivision.localeCompare(b.subDivision))
            .map((community) => (
              <TreeItem
                key={`company-${
                  company.companyId
                }-year-${year}-month-${month}-community-${community.projectId}${
                  isArchive ? "-archive" : ""
                }`}
                nodeId={`company-${
                  company.companyId
                }-year-${year}-month-${month}-community-${community.projectId}${
                  isArchive ? "-archive" : ""
                }`}
                label={`${community.subDivision} - ${community.projectNumber}`}
              >
                {jobs
                  .filter(
                    (job) =>
                      job.projectId === community.projectId &&
                      job.year === year.toString() &&
                      job.month === month
                  )
                  ?.sort((a, b) => a.jobAddress.localeCompare(b.jobAddress))
                  .map((job) => (
                    <TreeItem
                      key={`company-${
                        company.companyId
                      }-year-${year}-month-${month}-community-${
                        community.projectId
                      }-job-${job.jobId}${isArchive ? "-archive" : ""}`}
                      nodeId={`company-${
                        company.companyId
                      }-year-${year}-month-${month}-community-${
                        community.projectId
                      }-job-${job.jobId}${isArchive ? "-archive" : ""}`}
                      label={`${job.jobAddress} - ${job.projectNumber}`}
                      onClick={() => loadJobDetails(job.jobId)}
                    />
                  ))}
              </TreeItem>
            ))}
        </TreeItem>
      ))}
    </TreeItem>
  );

  const [isResizing, setIsResizing] = useState(false);

  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: React.MouseEvent) => {
      if (isResizing) {
        const newWidth = mouseMoveEvent.clientX;
        onWidthChange(newWidth);
      }
    },
    [isResizing, onWidthChange]
  );

  React.useEffect(() => {
    window.addEventListener("mousemove", resize as any);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize as any);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <Box className="flex flex-col h-full overflow-hidden relative">
      <div className="flex items-center h-12 pt-4 pb-2 px-2  border-b border-gray-200">
        <h2 className="text-sm font-semibold text-gray-600 uppercase tracking-wider">
          File Explorer
        </h2>
      </div>
      <Box className="flex-grow overflow-y-auto overflow-x-hidden bg-white">
        {Boolean(companies.length) ? (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={defaultExpandedNodeIds}
            sx={{
              width: "100%",
              maxWidth: "100%",
              transition: "max-width 0.3s ease-in-out, width 0.3s ease-in-out",
              [`& .${treeItemClasses.root}`]: {
                marginBottom: "8px",
              },
              [`& .${treeItemClasses.content}`]: {
                padding: "4px 8px",
              },
              [`& .${treeItemClasses.group}`]: {
                marginLeft: "16px",
                borderLeft: `1px dashed rgba(0, 0, 0, 0.2)`,
                paddingLeft: "8px",
              },
              "& .MuiTreeItem-label": {
                fontFamily: "Inter, sans-serif",
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              height: "100%", // Take up full height of parent
              "& .MuiTreeItem-content": {
                width: "100%",
                boxSizing: "border-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            {companies.map((company) => (
              <TreeItem
                key={`company-${company.companyId}`}
                nodeId={`company-${company.companyId}`}
                label={company.companyName}
              >
                {[
                  ...new Set(
                    communities
                      .filter(
                        (community) =>
                          community.companyId === company.companyId &&
                          isWithinThreshold(community.year, community.month)
                      )
                      .map((c) => c.year)
                  ),
                ]
                  .sort((a, b) => b - a)
                  .map((year) => renderYearContent(company, year, false))}
                <TreeItem
                  key={`company-${company.companyId}-archive`}
                  nodeId={`company-${company.companyId}-archive`}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FolderIcon sx={{ mr: 1 }} />
                      Archive
                    </Box>
                  }
                >
                  {[
                    ...new Set(
                      communities
                        .filter(
                          (community) =>
                            community.companyId === company.companyId &&
                            !isWithinThreshold(community.year, community.month)
                        )
                        .map((c) => c.year)
                    ),
                  ]
                    .sort((a, b) => b - a)
                    .map((year) => renderYearContent(company, year, true))}
                </TreeItem>
              </TreeItem>
            ))}
          </TreeView>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: 2,
              mt: 2,
            }}
          >
            <Skeleton variant="rounded" animation="wave" height={30} />
            <Skeleton variant="rounded" animation="wave" height={30} />
            <Skeleton variant="rounded" animation="wave" height={30} />
            <Skeleton variant="rounded" animation="wave" height={30} />
          </Box>
        )}
      </Box>
      <div
        className="absolute top-0 right-0 w-1 h-full cursor-col-resize bg-gray-300 hover:bg-gray-400"
        onMouseDown={startResizing}
      />
    </Box>
  );
};

export default FileTree;
