/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  AvatarGroup,
  Avatar,
  InputLabel,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { useData } from "../../providers/DataProvider";
import { BonusProps, BuilderInfo, RegionProps } from "../../providers/types";
import { ChangeEvent, useEffect, useState } from "react";
import RoleModal from "../../modals/RoleModal";
import ReviewerViewModal from "../../modals/ReviewerViewModal";
import {
  EyeIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import PayrollModal from "../../modals/PayrollModal";
import AxiosInstance from "../../utils/axiosInstance";
import BuilderSplitModal from "../../components/BuilderSplitModalReview";
import moment from "moment";
import { SelectChangeEvent } from "@mui/material/Select";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[]; // Allow value to be string or array of strings
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
};

const defaultFilter: Filters = {
  items: [],
  quickFilterValues: [""],
};

export default function Reviewer() {
  const {
    regions,
    bonuses,
    loadBonuses,
    role,
    loadingBonuses,
    selectedRegion,
    setSelectedRegion,
  } = useData();
  const [open, setOpen] = React.useState<boolean>(false);
  const [filt, setFilt] = React.useState<Filters>(defaultFilter);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [selectedStatusType, setSelectedStatusType] = React.useState<
    number | string | null
  >("All");
  const [selectedCommunity, setSelectedCommunity] =
    React.useState<string>("All");
  // Define a default 'All' region object
  const allRegions: RegionProps = {
    regionId: "All",
    regionName: "All Regions",
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  function stringAvatar(name: string) {
    return {
      sx: {
        width: 24,
        height: 24,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }
  const downloadFile = async (url: string, filename: string) => {
    try {
      const response = await AxiosInstance.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("File download error:", error);
    }
  };

  const downloadSelectionSheet = async (jobId: string, bonus: any) => {
    const address = bonus.jobAddress.replace(/\s+/g, "");
    await downloadFile(
      `/api/cba/SelectionSheetsPdf/${jobId}`,
      `${bonus?.projectNumber || ""}${bonus?.projectNumber ? "-" : ""}${
        bonus?.jobNumber || ""
      }${bonus?.jobNumber ? "-" : ""}${address}-SelectionSheet.pdf`
    );
  };

  const downloadBuilderFolder = async (jobId: string, bonus: any) => {
    const address = bonus.jobAddress.replace(/\s+/g, "");
    await downloadFile(
      `/api/cba/mergedpdf/${jobId}`,
      `${bonus?.projectNumber || ""}${bonus?.projectNumber ? "-" : ""}${
        bonus?.jobNumber || ""
      }${bonus?.jobNumber ? "-" : ""}${address}-ConstructionFolder.pdf`
    );
  };

  const formatStatus = (status: number, bonus?: BonusProps): string => {
    const isDefaultDate = (date: string | Date) =>
      moment(date).isSame(moment("0001-01-01"));

    switch (status) {
      case 1:
        return "New";
      case 2:
        return "PM Review";
      case 3:
        return "SPM Review";
      case 4:
        return "DM Review";
      case 5:
        return "Ops Review";
      case 6:
        return "Approved";
      case 7:
        return "Rejected";
      case 8:
        return "Busted";
      case 9:
        return bonus?.payrollProcessedOn &&
          !isDefaultDate(bonus.payrollProcessedOn)
          ? `Completed on ${moment(bonus.payrollProcessedOn).format(
              "MM/DD/YYYY"
            )}`
          : "Completed";
      case 10:
        return bonus?.payrollPaidOn && !isDefaultDate(bonus.payrollPaidOn)
          ? `Paid on ${moment(bonus.payrollPaidOn).format("MM/DD/YYYY")}`
          : "Paid";
      default:
        return "";
    }
  };

  const handleChipClick = (jobId: string) => {
    setSelectedJobId(jobId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmUpload = async () => {
    if (!selectedJobId) return;

    try {
      await AxiosInstance.put(
        `/api/cba/updatebimuploadstatus/${selectedJobId}`
      );
      setOpenDialog(false);
    } catch (error) {
      console.error("Error updating BIM upload status:", error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "jobAddress",
      headerName: "Address",
      minWidth: 200,
      maxWidth: 280,
      flex: 1,
      filterable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.jobAddress}>{params.value}</Tooltip>
      ),
    },
    {
      field: "subdivision",
      headerName: "Community",
      minWidth: 200,
      filterable: true,
    },
    {
      field: "regionName",
      headerName: "Region",
      minWidth: 200,
      filterable: true,
    },
    {
      field: "projectNumber",
      headerName: "Project",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      filterable: true,
      renderCell: (params) => {
        return params.row?.payoutInfo?.projectNumber;
      },
    },
    {
      field: "jobNumber",
      headerName: "Job",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      filterable: true,
    },
    {
      field: "closeDate",
      headerName: "Close Date",
      headerAlign: "center",
      align: "center",
      minWidth: 160,
      filterable: true,
      renderCell: (params) => {
        return moment(params.row.closeDate).isSame(moment("0001-01-01")) 
          ? "" 
          : moment(params.row.closeDate).format("MM/DD/YYYY");
      },
    },
    {
      field: "statusTypeId",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      filterable: true,
      renderCell: (params) => {
        return formatStatus(params.row.statusTypeId, params.row);
      },
    },
    {
      field: "builders",
      headerName: "Builders",
      filterable: true,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <AvatarGroup
            max={4}
            sx={{
              mt: 1,
              "& .MuiAvatar-root": {
                width: 28,
                height: 28,
                fontSize: 15,
                bgcolor: "#003057",
              },
              justifyContent: "center",
            }}
          >
            {params.row?.builders?.map((row: BuilderInfo) => {
              if (row.builderName.split(" ").length >= 2) {
                return (
                  <Tooltip title={row.builderName}>
                    <Avatar
                      alt={row?.builderName}
                      {...stringAvatar(row.builderName)}
                    />
                  </Tooltip>
                );
              } else {
                return (
                  <Avatar
                    alt={row?.builderName}
                    sx={{ height: 24, width: 24 }}
                  />
                );
              }
            })}
          </AvatarGroup>
        );
      },
    },
    {
      field: "id",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      minWidth: 260,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            {role?.trim() === "Uploader" && (
              <Tooltip title={"View Files"}>
                <IconButton href={`/upload?job=${params.row.jobId}`}>
                  <EyeIcon className="h-5 w-5 text-primary-600" />
                </IconButton>
              </Tooltip>
            )}
            {(params.row?.statusTypeId === 6 ||
              params.row?.statusTypeId === 9 ||
              params.row?.statusTypeId === 10) && (
              <Box>
                {role?.trim() === "Ops" &&
                  params.row?.biM360UploadIssue === true && (
                    <>
                      <Tooltip title="The BIM Construction Folder upload encountered an error. Please manually upload the file. Upon completion, the system will automatically update this status.">
                        <Chip
                          label="Upload Issue"
                          size="small"
                          color="error"
                          variant="outlined"
                          onClick={() => handleChipClick(params.row.jobId)}
                          clickable
                        />
                      </Tooltip>
                      {/* Dialog */}
                      <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        hideBackdrop
                        sx={{
                          boxShadow: "none",
                        }}
                        PaperProps={{
                          sx: {
                            boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
                            border: "1px solid #e0e0e0",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <DialogTitle>Confirm Manual Upload</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Please manually upload your construction folder to
                            BIM. Once you have completed the upload, click
                            "Confirm" to acknowledge the submission.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                          </Button>
                          <Button
                            onClick={handleConfirmUpload}
                            color="primary"
                            variant="contained"
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                <Tooltip title={"Download Construction Folder"}>
                  <IconButton
                    onClick={() =>
                      downloadBuilderFolder(params.row.jobId, params.row)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5 text-primary-600"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M19.5 21a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-5.379a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H4.5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h15Zm-6.75-10.5a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V10.5Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {(params.row?.statusTypeId === 6 ||
              params.row?.statusTypeId === 9 ||
              params.row?.statusTypeId === 10) && (
              <Box>
                <Tooltip title={"Download Selection Sheet"}>
                  <IconButton
                    onClick={() =>
                      downloadSelectionSheet(params.row.jobId, params.row)
                    }
                  >
                    <DocumentTextIcon className="h-5 w-5 text-primary-600" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Box>
              <BuilderSplitModal jobId={params.row.jobId} />
            </Box>
            {role?.trim() === "Payroll" && (
              <PayrollModal jobId={params.row.jobId as number} />
            )}
            {role?.trim() !== "Payroll" && role?.trim() !== "Uploader" && (
              <Box>
                {params.row.statusTypeId === 6 ? (
                  <PayrollModal jobId={params.row.jobId as number} />
                ) : (
                  <ReviewerViewModal jobId={params.row.jobId as number} />
                )}
              </Box>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleStatusTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as number | null;
    setSelectedStatusType(selectedValue);
    // Update filter based on selected status type
    updateFilter2("statusTypeId", selectedValue);
  };

  const handleCommunityChange = (value: string) => {
    setSelectedCommunity(value);
    updateFilter("subdivision", value === "All" ? null : value);
  };

  const updateFilter = (field: string, value: any) => {
    setFilt((prevFilt) => {
      const filteredItems = prevFilt.items.filter(
        (item) => item.field !== field
      );
      const newItems = value
        ? [
            {
              id: `${field}-${value}`,
              field: field,
              operator: "equals",
              value: value,
            },
          ]
        : [];

      return {
        ...prevFilt,
        items: [...filteredItems, ...newItems],
      };
    });
  };

  const updateFilter2 = (field: string, value: number | null) => {
    setFilt((prevFilt) => {
      const filteredItems = prevFilt.items.filter(
        (item) => item.field !== field && item.field !== "regionId"
      );

      let newItems: FilterItem[] = [];

      // Update status filter
      const statusFilter =
        //@ts-ignore
        value !== null && value !== "All"
          ? {
              id: `${field}-${value}`,
              field: field,
              operator: "equals",
              value: value.toString(),
            }
          : null;
      if (statusFilter) {
        newItems.push(statusFilter);
      }

      return {
        ...prevFilt,
        items: [...filteredItems, ...newItems],
      };
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [newValue],
    }));
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [""],
    }));
  };

  useEffect(() => {
    if (role) {
      loadBonuses(role);
    } else {
      setOpen(true);
    }
  }, [role]);

  useEffect(() => {
    if (selectedRegion) {
      updateFilter("regionName", selectedRegion.regionName);
    }
  }, [selectedRegion]);

  // Update the getUniqueCommunities function
  const getUniqueCommunities = () => {
    if (!Array.isArray(bonuses) || bonuses.length === 0) {
      return ["All"];
    }

    const filteredBonuses =
      selectedRegion && selectedRegion.regionId !== "All"
        ? bonuses.filter(
            (bonus: BonusProps) =>
              bonus.regionName === selectedRegion.regionName
          )
        : bonuses;

    const communities = filteredBonuses
      .map((bonus: BonusProps) => bonus.subdivision)
      .filter((subdivision): subdivision is string => !!subdivision); // Filter out null/undefined values

    const uniqueSortedCommunities = Array.from(new Set(communities)).sort(
      (a, b) => a.localeCompare(b)
    );

    return ["All", ...uniqueSortedCommunities];
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div className="flex-grow flex flex-col overflow-hidden bg-white rounded-lg shadow-md my-8">
        <div className="p-4 flex-shrink-0">
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="flex flex-row items-start space-x-2 w-full md:w-3/5 lg:w-1/2">
              <div className="relative w-full max-w-[300px]">
                <div className="relative flex items-center">
                  <input
                    type="text"
                    className="w-full pl-10 pr-10 h-[40px] min-w-48 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary-500"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                  />
                  <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center">
                    {searchTerm && (
                      <button onClick={clearSearch}>
                        <XCircleIcon className="h-5 w-5 text-blue-500" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <FormControl fullWidth sx={{ maxWidth: 300 }}>
                <InputLabel>Community</InputLabel>
                <Select
                  size="small"
                  label="Community"
                  value={selectedCommunity}
                  onChange={(event: SelectChangeEvent<string>) =>
                    handleCommunityChange(event.target.value as string)
                  }
                  inputProps={{ "aria-label": "Select Community" }}
                >
                  {getUniqueCommunities().map((community) => (
                    <MenuItem key={community} value={community}>
                      {community}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ maxWidth: 300 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  size="small"
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                  value={selectedStatusType || ""}
                  // @ts-ignore
                  onChange={handleStatusTypeChange}
                  inputProps={{ "aria-label": "Select Status Type" }}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  {/* Map status types to options */}
                  {bonuses
                    .reduce((unique, bonus) => {
                      if (
                        !unique.some(
                          // @ts-ignore
                          (obj) => obj.statusTypeId === bonus.statusTypeId
                        )
                      ) {
                        // @ts-ignore
                        unique.push(bonus);
                      }
                      return unique;
                    }, [])
                    .sort((a, b) =>
                      // @ts-ignore
                      formatStatus(a.statusTypeId).localeCompare(
                        // @ts-ignore
                        formatStatus(b.statusTypeId)
                      )
                    )
                    .map((uniqueBonus) => (
                      <MenuItem
                        // @ts-ignore
                        key={uniqueBonus.statusTypeId}
                        // @ts-ignore
                        value={uniqueBonus.statusTypeId}
                      >
                        {
                          //@ts-ignore
                          formatStatus(uniqueBonus.statusTypeId)
                        }
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="flex-grow overflow-auto">
          <DataGridPro
            sx={{
              height: "100%",
              "&": {
                border: "none",
                // Header styling
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f9fafb", // bg-gray-50
                  borderBottom: "1px solid #e5e7eb", // divide-gray-200
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: "1rem", // text-xs
                    color: "#374151", // text-gray-700
                  },
                },
                // Row styling
                "& .MuiDataGrid-row": {
                  backgroundColor: "white",
                  borderBottom: "1px solid #e5e7eb", // Add divider between rows
                  "&:hover": {
                    backgroundColor: "#f9fafb", // hover:bg-gray-50
                  },
                  "&:last-child": {
                    borderBottom: "none", // Remove border from last row
                  },
                },
                // Cell styling
                "& .MuiDataGrid-cell": {
                  border: "none",
                  padding: "1rem", // px-4 py-4
                  whiteSpace: "nowrap",
                  color: "#374151", // text-gray-500
                },
                // Align treeview toggle
                "& .MuiDataGrid-treeDataGroupingCellToggle": {
                  alignSelf: "auto",
                },
                // Remove default separators
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
                // Table body
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "white",
                },
                // Pagination styling (if needed)
                "& .MuiTablePagination-root": {
                  color: "#6b7280", // text-gray-500
                },
                // Remove focus outlines
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                  {
                    outline: "none",
                  },
                "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            getRowId={(row) => row.bonusId}
            paginationModel={paginationModel}
            onPaginationModelChange={(model: GridPaginationModel) =>
              setPaginationModel({
                page: model.page,
                pageSize: model.pageSize,
              })
            }
            filterModel={filt}
            onFilterModelChange={(newFilterModel) =>
              setFilt(newFilterModel as Filters)
            }
            disableRowSelectionOnClick
            columns={columns}
            rows={bonuses}
            rowCount={bonuses.length}
            slots={{
              noRowsOverlay: NoRows,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false,

                density: "comfortable",
                sx: {
                  padding: (theme) => theme.spacing(2),
                },
              },
            }}
            loading={loadingBonuses}
          />
        </div>
      </div>
      <RoleModal open={open} setOpen={setOpen} />
    </Container>
  );
}
