import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  Divider,
} from "@mui/material";
import {
  fileAttachmentsByTypesProps,
  JobDetailsProps,
} from "../../providers/types";

interface ChecklistProps {
  job: JobDetailsProps | undefined;
  selectChecklistItem: (file: fileAttachmentsByTypesProps) => void;
  selectedFile: fileAttachmentsByTypesProps | null;
  drawerWidth: number;
  onWidthChange: React.Dispatch<React.SetStateAction<number>>;
}

export default function Checklist({
  job,
  selectChecklistItem,
  selectedFile,
  drawerWidth,
  onWidthChange,
}: ChecklistProps) {
  if (!job) return null;

  const requiredFiles = job.fileAttachmentsByTypes.filter(
    (file) => file.isRequired
  );
  const optionalFiles = job.fileAttachmentsByTypes.filter(
    (file) => !file.isRequired
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Typography variant="h6">Checklist</Typography>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <List>
          {requiredFiles.map((file, index) => (
            <ListItem
              key={index}
              button
              selected={
                selectedFile?.fileAttachmentName === file.fileAttachmentName
              }
              onClick={() => selectChecklistItem(file)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={Boolean(file.fileAttachments.length)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={file.fileAttachmentName} />
            </ListItem>
          ))}

          {optionalFiles.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <ListItem>
                <ListItemText
                  primary="Optional Items"
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    color: "text.secondary",
                  }}
                />
              </ListItem>
              {optionalFiles.map((file, index) => (
                <ListItem
                  key={index}
                  button
                  selected={
                    selectedFile?.fileAttachmentName === file.fileAttachmentName
                  }
                  onClick={() => selectChecklistItem(file)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={Boolean(file.fileAttachments.length)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={file.fileAttachmentName} />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Box>
    </Box>
  );
}
