/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import FileTree from "../../components/Drawer/FileTree";
import SearchBar from "../../components/SearchBar";
import MainContent from "../../components/MainContent/MainContent";
import { useData } from "../../providers/DataProvider";
import { useEffect } from "react";
import UploaderViewModal from "../../modals/UploaderViewModel";
import axiosInstance from "../../utils/axiosInstance";
import { useState } from "react";

export default function UploadHome() {
  const {
    allJobs,
    companies,
    communities,
    selectedRegion,
    selectedCompany,
    loadCompanies,
    loadRegions,
    loadJobs,
    loadCommunities,
  } = useData();
  const [current, setCurrent] = React.useState<{
    files: any[];
    type: string;
    prev: any;
  }>({
    files: companies,
    type: "company",
    prev: {
      type: "company",
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const jobQuery = React.useMemo(() => searchParams.get("job"), [searchParams]);
  const { role } = useData();
  const [sidebarWidth, setSidebarWidth] = useState(320); // Default width
  const [isLoading, setIsLoading] = useState(false);

  const loadJobDetails = (jobId: number, role: string | null) => {
    setIsLoading(true);
    axiosInstance
      .get(`/api/cba/job-details/${jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setCurrent({
          ...current,
          files: response.data.fileAttachments,
          type: "file",
          prev: {
            jobId: jobId,
            job: response.data,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    loadRegions();
    loadCompanies();
    loadJobs();
  }, []);

  useEffect(() => {
    if (selectedCompany && selectedRegion) {
      loadCommunities();
    }
  }, [selectedCompany, selectedRegion]);

  useEffect(() => {
    if (jobQuery) {
      loadJobDetails(parseInt(jobQuery), role);
    }
  }, [jobQuery, role]);

  return (
    <div className="flex h-[calc(100vh-64px)] overflow-hidden">
      {/* Sidebar */}
      <div
        className="flex-shrink-0 border-r border-gray-200 overflow-hidden flex flex-col"
        style={{ width: `${sidebarWidth}px` }}
      >
        <FileTree
          companies={companies}
          communities={communities}
          jobs={allJobs as any}
          drawerWidth={sidebarWidth}
          current={current}
          setCurrent={setCurrent}
          onWidthChange={setSidebarWidth}
        />
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-hidden flex flex-col">
        {/* Header */}
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center space-x-4">
            <div className="flex-grow">
              <SearchBar
                current={current}
                setCurrent={setCurrent}
                setIsLoading={setIsLoading}
              />
            </div>
            <div className="flex space-x-2">
              {current.type === "file" && (
                <>
                  {current.prev.job &&
                    Boolean(current.files.length) &&
                    current.type === "file" && (
                      <UploaderViewModal
                        job={current.prev.job}
                        files={current.prev.job?.fileAttachments}
                      />
                    )}
                  <button
                    className="bg-white text-primary-500 border border-primary-500 px-3 py-1.5 rounded-md flex items-center text-sm"
                    onClick={() =>
                      (window.location.href = `/upload/create/${current.prev.jobId}`)
                    }
                  >
                    Edit Files
                  </button>
                </>
              )}
              <a
                href="/upload/create"
                className="bg-primary-500 text-white px-3 py-2 rounded-md flex items-center text-sm"
              >
                <PlusIcon className="h-4 w-4 mr-1" />
                Create
              </a>
            </div>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-grow overflow-auto">
          {selectedRegion && selectedCompany && (
            <MainContent
              current={current}
              setCurrent={setCurrent}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
