import React, { useEffect, useState } from "react";
import { BuilderInfo, JobDetailsProps } from "../../providers/types";
import AxiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";
import { TrashIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface BuilderSplitModalProps {
  job: JobDetailsProps;
  jobBuilders: BuilderInfo[];
  allBuilders: {
    builderEmail: string;
    builderName: string;
    builderNumber: number;
  }[];
  active: boolean;
  setJobBuilders: React.Dispatch<React.SetStateAction<BuilderInfo[]>>;
  reloadBuilders: () => void;
}

const BuilderSplitModal: React.FC<BuilderSplitModalProps> = ({
  job,
  jobBuilders,
  allBuilders,
  active,
  reloadBuilders,
}) => {
  const { accounts } = useMsal();
  const [open, setOpen] = useState<boolean>(false);
  const [builderSplits, setBuilderSplits] = useState<BuilderInfo[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleOpen = () => {
    if (!active) {
      alert("Can't edit a job actively in review");
      return;
    }
    setOpen(true);
    setBuilderSplits([...jobBuilders]);
  };

  const handleClose = () => {
    setOpen(false);
    setBuilderSplits([]);
  };

  const handleAddBuilder = () => {
    const newBuilder: BuilderInfo = {
      builderId: 0,
      jobId: job.jobId,
      builderName: "",
      builderNumber: 0,
      builderEmail: "",
      split: 0,
      splitAmount: 0,
      createdOn: "",
      createdBy: "",
      updatedOn: "",
      updatedBy: "",
      isActive: false,
    };
    setBuilderSplits([...builderSplits, newBuilder]);
  };

  const handleBuilderChange = (index: number, builderNumber: number) => {
    const newBuilderSplits = builderSplits.map((builderSplit, idx) => {
      if (idx === index) {
        const selectedBuilder = allBuilders.find(
          (builder) => builder.builderNumber === builderNumber
        );
        // Map the selected builder's details to the builderSplit,
        // ensuring that all relevant information is updated correctly.
        return selectedBuilder
          ? {
              ...builderSplit,
              builderId: selectedBuilder.builderNumber, // Assuming builderId needs to be updated
              builderName: selectedBuilder.builderName,
              builderEmail: selectedBuilder.builderEmail,
              builderNumber: selectedBuilder.builderNumber,
            }
          : builderSplit;
      }
      return builderSplit;
    });
    setBuilderSplits(newBuilderSplits);
  };

  const handleSplitChange = (index: number, split: number) => {
    const newBuilderSplits = builderSplits.map((builderSplit, idx) =>
      idx === index
        ? { ...builderSplit, split: Math.max(0, split) }
        : builderSplit
    );
    setBuilderSplits(newBuilderSplits);
  };

  const handleContinue = () => {
    if (!Boolean(builderSplits.length)) {
      alert("Please select a builder");
      return;
    }
    const hasNoName = builderSplits.some(
      (builderSplit) => builderSplit.builderName === ""
    );
    if (hasNoName) {
      alert("Missing builder name");
      return;
    }

    const hasZeroSplit = builderSplits.some(
      (builderSplit) => builderSplit.split === 0
    );
    if (hasZeroSplit) {
      alert("All builders must have a split greater than 0%.");
      return;
    }

    const totalSplit = builderSplits.reduce((acc, curr) => acc + curr.split, 0);
    if (builderSplits.length && totalSplit !== 100) {
      alert("Total split must equal 100%.");
      return;
    }

    // Check for duplicate builder names
    const namesSet = new Set();
    const hasDuplicateNames = builderSplits.some((builder) => {
      if (namesSet.has(builder.builderName)) {
        return true;
      }
      namesSet.add(builder.builderName);
      return false;
    });

    if (hasDuplicateNames) {
      alert("Duplicate builder names detected. Each builder must be unique.");
      return;
    }

    setIsSaving(true);

    AxiosInstance.post("/api/cba/builders/bulk", {
      JobId: job.jobId,
      IsUpdateByReviewer: false,
      UnusedBonusAmount: job.bonusDetail.bonus.unusedBonusAmount,
      UpdatedBy: accounts[0].username,
      Comments: "",
      BulkUpdateBuildersRequests: builderSplits.map((builder) => ({
        JobId: job.jobId,
        BuilderName: builder.builderName,
        BuilderEmail: builder.builderEmail,
        BuilderNumber: builder.builderNumber,
        Split: builder.split,
        SplitAmount:
          job.bonusDetail?.bonus?.closingBonusAmount && builder.split
            ? parseFloat(
                (
                  job.bonusDetail.bonus.closingBonusAmount *
                  (builder.split / 100)
                ).toFixed(2)
              )
            : 0,
        CreatedBy: accounts[0].username,
      })),
    })
      .then((res) => {
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to save builder splits:", error);
        alert("An error occurred while saving. Please try again.");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (allBuilders.length > 0) {
      setBuilderSplits([...jobBuilders]); // Initialize with jobBuilders when allBuilders is ready
    }
  }, [allBuilders, jobBuilders]);

  return (
    <>
      <div
        onClick={handleOpen}
        className="cursor-pointer hover:bg-gray-50 hover:border-primary-500 p-4 rounded-lg transition duration-300 ease-in-out border border-gray-200 shadow-sm"
      >
        <div className="flex justify-between items-center">
          <div>
            <div className="text-sm text-gray-500 mb-1">Builder</div>
            <div className="font-medium text-gray-900">
              {jobBuilders.length
                ? jobBuilders.map((b) => b.builderName).join(", ")
                : "Unassigned"}
            </div>
          </div>
          <div className="text-right">
            <div className="text-sm text-gray-500 mb-1">Split</div>
            <div className="font-medium text-gray-900">
              {jobBuilders.length
                ? jobBuilders.map((b) => `${b.split}%`).join(" / ")
                : "Unassigned"}
            </div>
          </div>
        </div>
      </div>

      {open && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              {/* Modal content */}
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Builder Split
                    </h3>
                    <div className="mt-2">
                      {/* Modal body content */}
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Builder
                              </th>
                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Split (%)
                              </th>
                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {builderSplits.map((builderSplit, index) => (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <select
                                    value={builderSplit.builderNumber}
                                    onChange={(e) =>
                                      handleBuilderChange(
                                        index,
                                        Number(e.target.value)
                                      )
                                    }
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                  >
                                    <option value="">Select Builder</option>
                                    {allBuilders.map((builder) => (
                                      <option
                                        key={builder.builderEmail}
                                        value={builder.builderNumber}
                                      >
                                        {`${builder.builderName} (${builder.builderEmail})`}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <input
                                      type="number"
                                      value={builderSplit.split}
                                      onChange={(e) =>
                                        handleSplitChange(
                                          index,
                                          parseInt(e.target.value, 10)
                                        )
                                      }
                                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                    />
                                    <span className="ml-2 text-gray-500">
                                      %
                                    </span>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <button
                                    onClick={() =>
                                      setBuilderSplits(
                                        builderSplits.filter(
                                          (_, idx) => idx !== index
                                        )
                                      )
                                    }
                                    className="text-red-600 hover:text-red-900"
                                  >
                                    <TrashIcon className="h-5 w-5" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {builderSplits.length < allBuilders.length && (
                        <button
                          onClick={handleAddBuilder}
                          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                          <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
                          Add Builder
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleContinue}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Saving...
                    </>
                  ) : (
                    'Save'
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BuilderSplitModal;
