/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  AppBar,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import Checklist from "../../components/Checklist";
import UploadZone from "../../components/UploadZone";
import BuilderSplitModal from "../../components/BuilderSplitModal";
import Comments from "../../modals/Comments";
import axiosInstance from "../../utils/axiosInstance";
import { useData } from "../../providers/DataProvider";
import {
  BuilderInfo,
  fileAttachmentsByTypesProps,
  JobDetailsProps,
} from "../../providers/types";
import { useMsal } from "@azure/msal-react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function FileUploadList() {
  const { role } = useData();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [job, setJob] = React.useState<JobDetailsProps | undefined>(undefined);
  const [jobBuilders, setJobBuilders] = React.useState<BuilderInfo[]>([]);
  const [ableToEdit, setAbletoEdit] = React.useState<boolean>(false);
  const [ableToEditBuilder, setAbletoEditBuilder] =
    React.useState<boolean>(false);
  const [allBuilders, setAllBuilders] = React.useState<
    {
      builderEmail: string;
      builderName: string;
      builderNumber: number;
    }[]
  >([]);
  const [selectedFile, setSelectedFile] =
    React.useState<fileAttachmentsByTypesProps | null>(null);
  const [readyToSubmit, setReadyToSubmit] = React.useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(320); // Default width

  const { id } = useParams();

  const selectChecklistItem = (file: fileAttachmentsByTypesProps) => {
    setSelectedFile(file);
  };

  const submitJob = () => {
    if (selectedFile && job) {
      const requiredAttachments = job.fileAttachmentsByTypes.filter(
        (item) => item.isRequired
      );
      const allHaveAttachments = requiredAttachments.every(
        (item) => item.fileAttachments.length > 0
      );

      if (allHaveAttachments) {
        if (!jobBuilders.length) {
          alert("Please assign a builder");
          return;
        }
        if (window.confirm("Are you sure you want to submit for review?")) {
          axiosInstance
            .put(`/api/cba/bonuses/${job.jobId}`, {
              JobId: job.jobId,
              BonusStatus: "Review",
              UpdatedBy: accounts[0].username,
            })
            .then(() => {
              navigate("/upload");
            });
        }
      } else {
        alert("Please add all required attachments before submitting");
      }
    }
  };

  const handleNext = () => {
    if (selectedFile && job) {
      const requiredFiles = job.fileAttachmentsByTypes.filter(
        (file) => file.isRequired
      );
      const optionalFiles = job.fileAttachmentsByTypes.filter(
        (file) => !file.isRequired
      );
      const allFiles = [...requiredFiles, ...optionalFiles];

      const currentIndex = allFiles.findIndex(
        (item) =>
          item.fileAttachmentTypeId === selectedFile.fileAttachmentTypeId
      );

      const nextIndex = (currentIndex + 1) % allFiles.length;
      setSelectedFile(allFiles[nextIndex]);
    }
  };

  const reloadBuilders = () => {
    axiosInstance
      .get(`/api/cba/job-details/${id}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setJobBuilders(response.data.builders);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  };

  const loadJobDetails = () => {
    axiosInstance
      .get(`/api/cba/job-details/${id}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setJobBuilders(response.data.builders);
        setSelectedFile(response.data.fileAttachmentsByTypes[0]);

        const requiredIds = new Set(
          Array.from({ length: 11 }, (_, i) => i + 1)
        );
        const presentIds = new Set(
          response.data.fileAttachmentsByTypes.map(
            (item: { fileAttachmentTypeId: any }) => item.fileAttachmentTypeId
          )
        );
        setReadyToSubmit([...requiredIds].every((id) => presentIds.has(id)));

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  };

  const reloadJob = () => {
    axiosInstance
      .get(`/api/cba/job-details/${id}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setJobBuilders(response.data.builders);
        const requiredIds = new Set(
          Array.from({ length: 11 }, (_, i) => i + 1)
        );
        const presentIds = new Set(
          response.data.fileAttachmentsByTypes.map(
            (item: { fileAttachmentTypeId: any }) => item.fileAttachmentTypeId
          )
        );
        setReadyToSubmit([...requiredIds].every((id) => presentIds.has(id)));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  };

  const loadAllBuilders = () => {
    axiosInstance
      .post(`/api/cba/builders/search`)
      .then((response) => {
        setAllBuilders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching job builder details:", error);
      });
  };

  const formatStatus = (status: string) => {
    switch (status.trim()) {
      case "New":
        return "Ready to Start";
      case "InPMReview":
        return "Awaiting PM Review";
      case "InSPMReview":
        return "Awaiting SPM Review";
      case "InDMReview":
        return "Awaiting DM Review";
      case "InOpsReview":
        return "Awaiting Ops Review";
      case "Approved":
        return "Approved";
      case "Rejected":
        return "Rejected";
      case "Busted":
        return "Busted";
      case "Completed":
        return "Completed";
      case "Paid":
        return "Paid";
      default:
        return status.length ? status : "New";
    }
  };

  React.useEffect(() => {
    if (id) {
      loadJobDetails();
      loadAllBuilders();
    }
  }, [id]);

  useEffect(() => {
    if (job?.reviewStatus) {
      setAbletoEdit(
        Boolean(job.reviewStatus) === false ||
          job.reviewStatus.trim() === "Rejected" ||
          job.reviewStatus.trim() === "New"
      );
    }
  }, [job?.reviewStatus]);

  useEffect(() => {
    if (job) {
      setAbletoEditBuilder(
        job.reviewStatus.trim() === "Rejected" ||
          job.reviewStatus.trim() === "New" ||
          job.reviewStatus === ""
      );
    }
  }, [job]);

  return (
    <div className="flex h-[calc(100vh-64px)]">
      {/* Sidebar */}
      <div
        className="flex-shrink-0 border-r border-gray-200 overflow-hidden flex flex-col"
        style={{ width: `${sidebarWidth}px` }}
      >
        <Checklist
          job={job}
          selectChecklistItem={selectChecklistItem}
          selectedFile={selectedFile}
          drawerWidth={sidebarWidth}
          onWidthChange={setSidebarWidth}
        />
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-hidden flex flex-col">
        {/* Header */}
        <div className="p-4 border-b border-gray-200">
          <div className="flex justify-between items-center mb-4">
            <Typography variant="h6">{`${job?.jobAddress} - ${job?.projectNumber}`}</Typography>
            <Typography variant="subtitle1">
              <strong>Status:</strong> {formatStatus(job?.reviewStatus ?? "")}
            </Typography>
          </div>
          {job && (
            <BuilderSplitModal
              job={job}
              jobBuilders={jobBuilders}
              allBuilders={allBuilders}
              setJobBuilders={setJobBuilders}
              reloadBuilders={reloadBuilders}
              active={ableToEditBuilder}
            />
          )}
        </div>

        {/* Main Content Area */}
        <div className="flex-grow overflow-auto">
          {job && selectedFile && (
            <UploadZone
              job={job}
              reloadJob={reloadJob}
              selectChecklistItem={selectChecklistItem}
              selectedFile={selectedFile}
            />
          )}
        </div>

        {/* Footer */}
        <div className="border-t border-gray-200 bg-white">
          <div className="flex justify-between items-center p-4">
            <div>{job && <Comments jobId={job.jobId} />}</div>
            <div>
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              <Button
                variant="contained"
                onClick={submitJob}
                disabled={!ableToEdit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
