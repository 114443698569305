import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import FolderImg from "../../assets/images/folder.png";
import axiosInstance from "../../utils/axiosInstance";
import { useData } from "../../providers/DataProvider";
import { monthNames } from "../../providers/types";
import FileModal from "../../modals/FileModal";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

interface MainContentProps {
  current: {
    files: any[];
    type: string;
    prev: any;
  };
  setCurrent: React.Dispatch<
    React.SetStateAction<{
      files: any[];
      type: string;
      prev: any;
    }>
  >;
  isLoading: boolean; // Add this new prop
}

const MainContent: React.FC<MainContentProps> = ({
  current,
  setCurrent,
  isLoading,
}) => {
  const { role, companies, selectedCompany, selectedRegion } = useData();

  function handleClick(file: any) {
    if (!selectedCompany || !selectedRegion) return;

    if (current.type === "company") {
      axiosInstance
        .get(
          `/api/cba/communities-summary/${selectedCompany?.companyId}/${selectedRegion.regionId}`
        )
        .then((res) => {
          const data = res.data;
          const uniqueYears = new Set(data.map((item: any) => item.year));
          const uniqueYearsArray = Array.from(uniqueYears);
          //@ts-ignore
          const sortedUniqueYears = uniqueYearsArray.sort((a, b) => a - b);
          setCurrent({
            files: sortedUniqueYears,
            type: "year",
            prev: {
              ...current.prev,
              type: "company",
              files: current.files,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    } else if (current.type === "year") {
      axiosInstance
        .get(
          `/api/cba/communities-summary/${selectedCompany?.companyId}/${selectedRegion.regionId}/`
        )
        .then((res) => {
          const data = res.data;

          // Filter out the items where item.year does not match the file (year)
          const filteredData = data.filter(
            (item: { year: any }) => item.year === file
          );

          // Extract the unique months from the filtered data
          const uniqueMonthsNumbers = new Set(
            filteredData.map((item: { month: any }) => item.month)
          );

          // Convert the unique month numbers to month names
          const uniqueMonthsNames = Array.from(uniqueMonthsNumbers)
            ?.sort((a: any, b: any) => a - b)
            .map((monthNumber) => monthNames[(monthNumber as any) - 1]);

          setCurrent({
            files: uniqueMonthsNames,
            type: "month",
            prev: {
              ...current.prev,
              type: "year",
              files: current.files,
              year: file,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    } else if (current.type === "month") {
      const monthNumber = monthNames.indexOf(file) + 1;
      axiosInstance
        .get(
          `/api/cba/communities-summary/${selectedCompany?.companyId}/${selectedRegion.regionId}/${current.prev.year}/${monthNumber}/`
        )
        .then((res) => {
          const data = res.data;
          setCurrent({
            files: data,
            type: "job",
            prev: {
              ...current.prev,
              type: "month",
              month: monthNumber,
              files: current.files,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    } else if (current.type === "job") {
      axiosInstance
        .get(`/api/cba/jobs/${file.projectId}`)
        .then((res) => {
          const data = res.data;
          setCurrent({
            files: data.filter(
              (job: { month: any }) => job.month === current.prev.month
            ),
            type: "address",
            prev: {
              ...current.prev,
              type: "job",
              job: file.projectId,
              files: current.files,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    } else if (current.type === "address") {
      axiosInstance
        .get(`/api/cba/job-details/${file.jobId}`, {
          headers: {
            roleType: role,
          },
        })
        .then((res) => {
          const data = res.data;

          setCurrent({
            files: data?.fileAttachments,
            type: "file",
            prev: {
              ...current.prev,
              type: "address",
              files: current.files,
              jobId: file.jobId,
              job: data,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    }
  }

  useEffect(() => {
    if (companies || selectedCompany) {
      setCurrent({
        ...current,
        files: companies || [selectedCompany],
        type: "company",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, selectedCompany]);

  const reviewStatus = current.prev?.job?.reviewStatus?.trim();

  const formatStatus = (status: string) => {
    const commonClasses = "flex items-center gap-2";

    if (status === "Rejected") {
      return (
        <div className={`${commonClasses} text-red-600`}>
          <ExclamationCircleIcon className="w-5 h-5" />
          <span>Rejected</span>
        </div>
      );
    }

    const statusMap: { [key: string]: string } = {
      New: "Ready to Start",
      InPMReview: "Awaiting PM Review",
      InSPMReview: "Awaiting SPM Review",
      InDMReview: "Awaiting DM Review",
      InOpsReview: "Awaiting Ops Review",
      Complete: "Complete",
      Busted: "Busted",
      Paid: "Paid",
    };

    const statusText = statusMap[status] || status;
    const colorClass = ["Busted", "Rejected"].includes(status)
      ? "text-red-600"
      : "text-green-600";

    return (
      <div className={`${commonClasses} ${colorClass}`}>
        {statusText && (
          <>
            <CheckCircleIcon className="w-5 h-5" />
            <span>{statusText}</span>
          </>
        )}
      </div>
    );
  };

  console.log(reviewStatus);

  return (
    <div className="p-4 w-full h-full overflow-auto relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70 z-50">
          <CircularProgress />
        </div>
      )}
      {current.type === "file" && (
        <div className="ml-1 mb-4">{formatStatus(reviewStatus)}</div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {current.files?.map((file, index) => {
          const cardContent = (
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden hover:border-primary-500 hover:border-2 transition-all relative">
              {isLoading && (
                <div className="absolute top-2 right-2 z-10">
                  <CircularProgress size={20} thickness={4} />
                </div>
              )}
              <div className="flex items-center justify-center h-32 bg-gray-100">
                <img src={FolderImg} alt="Folder" className="h-20" />
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium text-gray-500">
                    {current.type.charAt(0).toUpperCase() +
                      current.type.slice(1)}
                  </span>
                  <span className="px-2 py-1 text-xs font-semibold text-blue-600 bg-blue-100 rounded-full">
                    {current.type}
                  </span>
                </div>
                <h3 className="text-lg font-semibold text-gray-900 truncate">
                  {current.type === "company" && file?.companyName}
                  {current.type === "year" && file}
                  {current.type === "month" && file}
                  {current.type === "job" &&
                    `${file?.subDivision || ""} ${
                      file?.projectNumber ? "-" + file?.projectNumber : ""
                    }`}
                  {current.type === "address" &&
                    `${file?.jobAddress || ""} - ${file?.projectNumber || ""}`}
                </h3>
              </div>
            </div>
          );

          return current.type === "file" ? (
            <FileModal defaultIndex={index} key={index} current={current} />
          ) : (
            <button
              key={index}
              onClick={() => handleClick(file)}
              className="text-left"
              disabled={isLoading}
            >
              {cardContent}
            </button>
          );
        })}
      </div>

      {!current.files.length && current.type !== "file" && (
        <div className="flex justify-center items-center h-64">
          <CircularProgress />
        </div>
      )}
      {!current.files.length && current.type === "file" && (
        <div className="flex justify-center items-center h-64">
          <div className="text-center">
            <p className="text-gray-600 mb-4">No files uploaded to this job</p>
            <a
              href={`/upload/create/${current.prev.jobId}`}
              className="px-6 py-2 bg-primary-500 text-white rounded-md hover:brightness-150 transition-colors duration-300"
            >
              Upload Now
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainContent;
