import React, { useState } from "react";
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { JobProps } from "../../types";
import { useData } from "../../providers/DataProvider";
import { CommunitiesProps } from "../../providers/types";
import axiosInstance from "../../utils/axiosInstance";

interface SearchBarProps {
  current: {
    files: any[];
    type: string;
    prev: any;
  };
  setCurrent: React.Dispatch<
    React.SetStateAction<{
      files: any[];
      type: string;
      prev: any;
    }>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SearchBar({ current, setCurrent, setIsLoading }: SearchBarProps) {
  const { allJobs: jobs, communities, role, companies } = useData();
  const [searchTerm, setSearchTerm] = useState("");
  const [jobResults, setJobResults] = useState<JobProps[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    const uniqueCommunities = new Map<number, CommunitiesProps>();
    communities.forEach((community) => {
      if (
        community?.subDivision?.toLowerCase()?.includes(value?.toLowerCase())
      ) {
        uniqueCommunities.set(community.projectId, community);
      }
    });

    const filteredJobs = jobs.filter((job) =>
      job.jobAddress?.toLowerCase().includes(value?.toLowerCase())
    );

    setJobResults(filteredJobs as any);
  };

  const handleJobSelect = (job: JobProps) => {
    setSearchTerm(job.jobAddress || "");
    setIsLoading(true);

    axiosInstance
      .get(`/api/cba/job-details/${job.jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setCurrent({
          ...current,
          files: response.data.fileAttachments,
          type: "file",
          prev: {
            jobId: job.jobId,
            job: response.data,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const clearSearch = () => {
    setSearchTerm("");
    setIsFocused(false);
  };

  const handleReset = () => {
    clearSearch();
    setCurrent({
      files: companies,
      type: "company",
      prev: {
        type: "company",
      },
    });
  };

  return (
    <div className="relative w-full">
      <div className="relative flex items-center">
        <input
          type="text"
          className="w-full pl-10 pr-20 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
        />
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
          {/* {searchTerm && (
            <button onClick={clearSearch}>
              <XMarkIcon className="h-5 w-5 text-gray-400" />
            </button>
          )} */}
          {searchTerm && (
            <button onClick={handleReset}>
              <XCircleIcon className="h-5 w-5 text-blue-500" />
            </button>
          )}
        </div>
      </div>
      {searchTerm && isFocused && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {jobResults.length > 0 ? (
            jobResults.map((job, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onMouseDown={() => handleJobSelect(job)}
              >
                {job.jobAddress} - {job.jobNumber}
              </li>
            ))
          ) : (
            <li className="px-4 py-2">No job results found</li>
          )}
        </ul>
      )}
    </div>
  );
}
