import React, { useState, useRef, useEffect } from "react";
import { useData } from "../../providers/DataProvider";
import { RegionProps } from "../../providers/types";

const RegionSwitcher: React.FC = () => {
  const { regions, selectedRegion, updateSelectedRegion } = useData();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRegionSelect = (region: RegionProps) => {
    updateSelectedRegion(region);
    setIsOpen(false);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out"
      >
        <span className="mr-2">
          {selectedRegion ? selectedRegion.regionName : "Select Region"}
        </span>
        <svg
          className={`w-4 h-4 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
          {regions.map((region) => (
            <button
              key={region.regionId}
              onClick={() => handleRegionSelect(region)}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition duration-150 ease-in-out"
            >
              {region.regionName}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RegionSwitcher;
