import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useData } from "../../providers/DataProvider";
import logo from "../../assets/images/logo-white.png";
import { SignOutButton } from "../Buttons/SignOutButton";
import RegionSwitcher from "../RegionSwitcher";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const { accounts } = useMsal();
  const { setRole, role, loadDataForRole } = useData();
  const navigate = useNavigate();
  const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
  const roleMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        roleMenuRef.current &&
        !roleMenuRef.current.contains(event.target as Node)
      ) {
        setIsRoleMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleRoleMenu = () => setIsRoleMenuOpen(!isRoleMenuOpen);

  const handleRoleSelect = (selectedRole: string) => {
    setRole(selectedRole);
    setIsRoleMenuOpen(false);
    loadDataForRole(selectedRole);
    // Reload the current page
    navigate(0);
  };

  return (
    <nav className="bg-primary-500 border-b border-gray-200 px-4 py-2.5 fixed left-0 right-0 top-0 z-50">
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex justify-start items-center">
          {/* Mobile menu button */}
          <button
            data-drawer-target="drawer-navigation"
            data-drawer-toggle="drawer-navigation"
            aria-controls="drawer-navigation"
            className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100"
          >
            <svg
              aria-hidden="true"
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              aria-hidden="true"
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Toggle sidebar</span>
          </button>
          <Link to="/" className="flex items-center justify-between mr-4">
            <img src={logo} className="mr-3 h-11" alt="Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
              Construction Bonus
            </span>
          </Link>
        </div>
        <div className="flex items-center lg:order-2">
          <div className="mr-4">
            <RegionSwitcher />
          </div>
          <div className="relative mr-4" ref={roleMenuRef}>
            <button
              onClick={toggleRoleMenu}
              className="flex items-center justify-center bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-md transition duration-150 ease-in-out"
            >
              <span className="mr-2">{role || "Select Role"}</span>
              <svg
                className={`w-4 h-4 transition-transform duration-200 ${
                  isRoleMenuOpen ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isRoleMenuOpen && (
              <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                {accounts[0].idTokenClaims &&
                  accounts[0].idTokenClaims?.roles
                    ?.filter((r) => r !== "Admin")
                    ?.sort((a, b) => a.localeCompare(b))
                    ?.map((r, index) => (
                      <button
                        key={index}
                        onClick={() => handleRoleSelect(r)}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition duration-150 ease-in-out"
                      >
                        {r}
                      </button>
                    ))}
              </div>
            )}
          </div>
          <SignOutButton />
        </div>
      </div>
    </nav>
  );
};

export default Header;
