import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { CircularProgress } from "@mui/material";
import FileIcon from "@mui/icons-material/FilePresentOutlined";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import {
  fileAttachmentsByTypesProps,
  fileAttachmentsProps,
  JobDetailsProps,
} from "../../providers/types";
import axiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";
import { twMerge } from "tailwind-merge";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Modal, Box } from "@mui/material";

interface UploadZoneProps {
  job: JobDetailsProps;
  reloadJob: () => void;
  selectChecklistItem: (file: fileAttachmentsByTypesProps) => void;
  selectedFile: fileAttachmentsByTypesProps;
}

export default function UploadZone({
  job,
  reloadJob,
  selectChecklistItem,
  selectedFile,
}: UploadZoneProps) {
  const [files, setFiles] = useState<fileAttachmentsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const { accounts } = useMsal();
  const [previewFile, setPreviewFile] = useState<fileAttachmentsProps | null>(
    null
  );

  function sanitizeFileName(inputString: string): string {
    const extensionMatch = inputString.match(/\.[0-9a-z]+$/i);
    const extension = extensionMatch ? extensionMatch[0] : "";
    const baseName = inputString.replace(/\.[0-9a-z]+$/i, "");
    const sanitizedBaseName = baseName.replace(/[^a-zA-Z0-9_-]/g, "");
    return sanitizedBaseName + extension;
  }

  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true);

    for (const file of acceptedFiles) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const uploadResponse = await axiosInstance.post(
          `api/cba/upload-file-attachment?jobId=${job?.jobId}&fileTypeId=${
            // @ts-ignore
            selectedFile?.fileAttachmentTypeId
          }&fileName=${sanitizeFileName(file.name)}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse.status !== 200) {
          throw new Error(`Upload failed: ${uploadResponse.statusText}`);
        }

        const newFile: fileAttachmentsProps = {
          ...uploadResponse.data,
          fileBlob: await fetchFileStream(uploadResponse.data.fileAttachmentId),
        };

        setFiles((prevFiles) => [...prevFiles, newFile]);
      } catch (error) {
        console.error("Error handling file upload:", error);
        alert("Error uploading file");
      }
    }
    reloadJob();
    setLoading(false);
  };

  const handleRemoveFile = (fileToRemove: fileAttachmentsProps) => {
    axiosInstance
      .delete(`api/cba/file-attachments/${fileToRemove.fileAttachmentTypeId}`, {
        data: {
          FileAttachmentId: fileToRemove.fileAttachmentId,
          UpdatedBy: accounts[0].username,
        },
      })
      .then((res) => {
        setFiles((currentFiles) =>
          currentFiles.filter(
            (file) => file.fileAttachmentId !== fileToRemove.fileAttachmentId
          )
        );
        reloadJob();
      });
  };

  const fetchFileStream = async (fileAttachmentId: number) => {
    try {
      if (!fileAttachmentId) throw new Error();
      const response = await axiosInstance.get(
        `/api/cba/attachmentfile/${fileAttachmentId}`,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching file stream:", error);
      return null;
    }
  };

  const fetchFiles = useCallback(async () => {
    if (job && selectedFile) {
      const selectedFileData = job.fileAttachmentsByTypes.find(
        (item) =>
          item.fileAttachmentTypeId === selectedFile.fileAttachmentTypeId
      );

      if (selectedFileData) {
        const filesWithBlobs = await Promise.all(
          selectedFileData.fileAttachments.map(async (file) => {
            const fileBlob = await fetchFileStream(file.fileAttachmentId);
            return { ...file, fileBlob };
          })
        );
        setFiles(filesWithBlobs);
      }
    }
  }, [job, selectedFile]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const active = useMemo(
    () =>
      job.reviewStatus === "" ||
      job.reviewStatus.trim() === "Rejected" ||
      job.reviewStatus.trim() === "New",
    [job]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !active,
    multiple: true,
    maxFiles: 5,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const getFileIcon = (fileName: string) => {
    if (fileName?.toLowerCase().endsWith(".pdf")) {
      return <PictureAsPdfIcon />;
    } else if (/\.(jpg|jpeg|png|gif)$/i.test(fileName)) {
      return <ImageIcon />;
    } else {
      return <InsertDriveFileIcon />;
    }
  };

  const handleFileClick = (file: fileAttachmentsProps) => {
    setPreviewFile(file);
  };

  const handleClosePreview = () => {
    setPreviewFile(null);
  };

  return (
    <div className="px-4 pt-2 flex flex-col h-full overflow-hidden">
      <div
        {...getRootProps()}
        className={twMerge(
          "p-8 mb-4 border-2 border-dashed rounded-lg cursor-pointer transition-colors flex flex-col items-center justify-center",
          isDragActive
            ? "border-primary-500 bg-primary-50"
            : !active
            ? "border-gray-300 hover:border-primary-300 hover:bg-primary-50"
            : "border-gray-300 bg-gray-100"
        )}
      >
        <input {...getInputProps()} />
        {loading ? (
          <CircularProgress size={40} className="text-primary-500 mb-4" />
        ) : (
          <div className="text-4xl mb-4">{isDragActive ? "📂" : "📁"}</div>
        )}
        <div className="text-center">
          {active ? (
            <p
              className={twMerge(
                "text-lg font-medium",
                isDragActive ? "text-primary-600" : "text-gray-600"
              )}
            >
              {isDragActive
                ? "Drop your files here ..."
                : "Drag and drop files here"}
            </p>
          ) : (
            <p className="text-red-600 text-lg font-medium">
              Cannot edit jobs in review, completed, or busted status
            </p>
          )}
          {active && !isDragActive && (
            <p className="text-gray-400 mt-2">or click to select files</p>
          )}
        </div>
      </div>

      <div className="flex-grow overflow-y-auto">
        <ul className="divide-y divide-gray-200">
          {files.map((file, index) => (
            <li
              key={index}
              className="py-4 flex items-center hover:bg-gray-50 transition-colors duration-150 ease-in-out border-b border-gray-200 last:border-b-0 cursor-pointer"
              onClick={() => handleFileClick(file)}
            >
              <div className="flex-shrink-0 ml-4 text-gray-400">
                {getFileIcon(file.fileName)}
              </div>
              <div className="ml-3 flex-grow">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {file.fileName}
                </p>
                <div className="flex gap-3 items-center">
                  <p className="text-xs text-gray-500">
                    Uploaded on: {new Date(file.createdOn).toLocaleDateString()}
                  </p>
                  <p className="text-xs text-blue-500 italic mr-2">
                    Click to preview
                  </p>
                </div>
              </div>
              {active && (
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleRemoveFile(file);
                  }}
                  className="mr-4 flex-shrink-0 text-gray-400 hover:text-red-500 transition-colors duration-150 ease-in-out"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* File Preview Modal */}
      <Modal
        open={previewFile !== null}
        onClose={handleClosePreview}
        aria-labelledby="file-preview-modal"
        aria-describedby="file-preview-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-7xl bg-white rounded-lg shadow-xl outline-none">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-900">
                {previewFile?.fileName}
              </h2>
              <button
                onClick={handleClosePreview}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <CloseIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-4 h-[80vh] overflow-auto">
              {previewFile?.fileBlob && (
                <iframe
                  src={URL.createObjectURL(previewFile.fileBlob)}
                  className="w-full h-full"
                  title="File Preview"
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>

      <div className="mt-4 p-4 bg-gray-50 border-t border-gray-200">
        <a
          href="https://highlandhomescorp.sharepoint.com/sites/ConstructionManual/SitePages/Document-Library.aspx?OR=Teams-HL&CT=1712327408604&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI1MC8yNDAyMjkyNDUxNyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
          target="_blank"
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <FileIcon className="mr-2 h-5 w-5 text-green-500" />
          Download Sample Templates
        </a>
      </div>
    </div>
  );
}
